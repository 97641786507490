<!--
 * @Description: 巡查管理--巡查分类--列表页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors: 小广
 * @LastEditTime: 2019-06-28 14:28:37
 -->
<template>
  <div class="InspectManagerCategoryList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-select
          label="巡查类型"
          v-model="searchParams.type"
          :options="typeOps"
          @change="typeChange"
        ></v-select>
        <v-select2
          label="所属公司"
          v-model="searchParams.regionId"
          v-bind="regionSel2Params"
          @onChange="onRegionIdChange"
        >
        </v-select2>
        <v-select
          label="一级分类"
          asyncOptions
          v-model="searchParams.firstLevel"
          :options="firstOps"
          @change="firstLevelChange"
        ></v-select>
        <v-select
          label="二级分类"
          asyncOptions
          v-model="searchParams.secondLevel"
          :options="secondOps"
        ></v-select>
        <v-datepicker
          label="最后操作时间"
          type="rangedatetimer"
          :startTime.sync="searchParams.startTime"
          :endTime.sync="searchParams.endTime"
        ></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          type="text"
          permission="update"
          @click="edit(scope.row)"
        ></v-button>
        <v-button
          text="删除"
          permission="delete"
          type="text"
          @click="deleteHandle(scope.row)"
        ></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button
          text="批量删除"
          permission="batchDelete"
          @click="batchDeleteHandle(scope.selectedData)"
        ></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getCategoryListURL,
  getCategoryFirstURL,
  getCategorySecondURL,
  handleInspectCategoryURL,
  batchDeleteCategoryUrl,
} from "./api";
import { typeOps, typeMap } from "./map";
import { regionParams } from "common/select2Params";
import { json } from "body-parser";

export default {
  name: "",
  data() {
    return {
      searchUrl: getCategoryListURL,
      typeOps: typeOps(1),
      regionSel2Params: regionParams,
      firstOps: [
        {
          text: "全部",
          value: undefined,
        },
      ],
      secondOps: [
        {
          text: "全部",
          value: undefined,
        },
      ],
      searchParams: {
        type: undefined,
        firstLevel: undefined,
        secondLevel: undefined,
        startTime: "",
        endTime: "",
      },
      headers: [
        {
          prop: "typeStr",
          label: "巡查类型",
          formatter(row) {
            let typeStr = typeMap[row.type];
            return typeStr;
          },
        },
        {
          prop: "parentName",
          label: "一级分类",
          formatter(row) {
            return row.level === 2 ? row.parentName : row.name;
          },
        },
        {
          prop: "name",
          label: "二级分类",
          formatter(row) {
            return row.level === 2 ? row.name : "";
          },
        },
        {
          prop: "pointCount",
          label: "关联巡查点",
          align: "right",
        },
        {
          prop: "updateUsername",
          label: "操作人",
        },
        {
          prop: "regionName",
          label: "所属公司",
        },
        {
          prop: "updateTime",
          label: "最后操作时间",
        },
      ],
    };
  },

  mounted() {
    if (
      this.searchParams.type ||
      this.searchParams.firstLevel ||
      this.searchParams.secondLevel
    ) {
      this.requestForCategoryFirst();
      this.requestForCategorySecond();
    }
  },

  methods: {
    typeChange(value) {
      this.$set(this.searchParams, "firstLevel", undefined);
      this.$set(this.searchParams, "secondLevel", undefined);
      /// 巡查类型变化，一级二级分类数据都重新获取
      this.$set(this.searchParams, "type", value);
      this.requestForCategoryFirst();
      this.requestForCategorySecond();
    },

    firstLevelChange(value) {
      this.$set(this.searchParams, "secondLevel", undefined);
      /// 一级分类改变 二级分类数据重新获取
      this.$set(this.searchParams, "firstLevel", value);
      this.requestForCategorySecond();
    },

    create() {
      this.$router.push({
        name: "inspectCategoryForm",
      });
    },
    edit(row) {
      this.$router.push({
        name: "inspectCategoryForm",
        query: {
          id: row.id,
        },
      });
    },

    // 删除的操作
    deleteHandle(row) {
      let _this = this;
      this.$confirm(
        "请确认是否执行此操作。已关联巡查点的巡查分类不能删除，需要先解除关联",
        {
          title: "提示",
        }
      ).then((confirm) => {
        if (confirm) {
          _this.requestForDelete(row);
        }
      });
    },

    batchDeleteHandle(SelectData) {
      console.log(SelectData, "++++");
      if(SelectData.data.length === 0) {
        this.$message({
          type: 'error',
          message: '请先选择要操作的数据',
          center: true
        })
        return
      }
      let _this = this;
      let list = [];
      this.$confirm(
        "请确认是否执行此操作。已关联巡查点的巡查分类不能删除，需要先解除关联",
        {
          title: "提示",
        }
      ).then((confirm) => {
        if (confirm) {
          SelectData.data.forEach((item) => {
            list.push(item.id);
          });
          _this.batchDeleteFun(list);
        }
      });
    },

    batchDeleteFun(list) {
      let _this = this;
      this.$axios({
        method: "POST",
        url: batchDeleteCategoryUrl,
        data: list,
      }).then((res) => {
        if (res.status === 100) {
          _this.$refs.list.searchData();
          _this.$message({
            type: "success",
            message: "操作成功",
            center: true,
          });
        }
      });
    },

    // 获取一级分类
    requestForCategoryFirst() {
      let _this = this;
      let getParams = {
        params: {
          type: this.searchParams.type,
          regionId: this.searchParams.regionId,
        },
      };
      this.$axios.get(getCategoryFirstURL, getParams).then((res) => {
        if (res.status === 100) {
          // 处理一级分类
          let categoryList = res.data || [];
          let tempList = [
            {
              text: "全部",
              value: undefined,
            },
          ];
          if (categoryList.length) {
            categoryList.forEach((item) => {
              tempList.push({
                text: item.name,
                value: item.categoryId,
              });
            });
          }
          _this.firstOps = tempList;
        }
      });
    },

    // 获取二级分类
    requestForCategorySecond() {
      let _this = this;
      let getParams = {
        params: {
          type: this.searchParams.type,
          parentId: this.searchParams.firstLevel,
        },
      };
      this.$axios.get(getCategorySecondURL, getParams).then((res) => {
        if (res.status === 100) {
          // 处理二级分类
          let categoryList = res.data || [];
          let tempList = [
            {
              text: "全部",
              value: undefined,
            },
          ];
          if (categoryList.length) {
            categoryList.forEach((item) => {
              tempList.push({
                text: item.name,
                value: item.categoryId,
              });
            });
          }
          _this.secondOps = tempList;
        }
      });
    },

    // 删除的请求
    requestForDelete(row) {
      let _this = this;
      let requestUrl = `${handleInspectCategoryURL}/${row.id}`;
      this.$axios.delete(requestUrl).then((res) => {
        if (res.status === 100) {
          _this.$refs.list.searchData();
          _this.$message({
            type: "success",
            message: "操作成功",
            center: true,
          });
        }
      });
    },

    async onRegionIdChange() {
      this.requestForCategoryFirst();
    },
  },
};
</script>
